<template>
  <img
    :src="imgSrc"
    :style="style"
    :alt="alt"
    class="app-img"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  src: string
  alt: string
  width?: string
  height?: string
}

const props = defineProps<Props>()

/**
 * NOTE: ローカル開発サーバー, ビルド時, Storybook で必要な画像パスが異なるため、import.mega.glob を使用して出し分けている
 * https://vitejs.dev/guide/features#glob-import
 */
const sources = import.meta.glob('@/assets/img/**/*', {
  import: 'default',
  eager: true,
})

const imgSrc = computed(() => {
  const _src = Object.keys(sources).find(s => s.indexOf(props.src) > 0) || ''
  return sources[_src] as string
})

const style = computed(() => ({
  '--width': `${props.width}px`,
  '--height': `${props.height}px`,
}))
</script>

<style lang="scss" scoped>
.app-img {
  width: var(--width);
  height: var(--height);
}
</style>
